.LocationPath {
  display: block;
  padding: 24px 100px 0 100px;
  font-size: 14px;
  &-link{
    color: #2478F8;
    &:hover{
      text-decoration: underline;
    }
    &:visited{
      color: #2478F8;
    }
  }
}

