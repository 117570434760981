.menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  transform: translateX(0);
  transition: all 0.6s;
  z-index: 101;

  &-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #0D224266 40%;
    right: 424px;
    opacity: .75;
  }
}
