@import "src/styles/colors";

.items {
  position: relative;
  display: flex;
  flex-direction: column;

  height: 100%;
  padding: 24px 100px 32px;

  &-content {
    background: white;
    padding: 24px 24px 16px;

    &-input {
      font-weight: 400;
      pointer-events: none;
      max-width: 140px;
      width: 100%;
      font-size: 14px;
      line-height: 22px;
      border: 1px solid #D9D9D9;
      border-radius: 4px;
      padding: 7px 12px;

      &-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $geekblue-10;
      }

      &-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-right: 8px;
      }

      &-arrow {
        position: absolute;
        top: 10px;
        right: 13px;
      }

      &-last {
        pointer-events: none;
      }
    }
  }

  &-departures {
    display: flex;
    padding-left: 12px;
    flex-direction: row;
    gap: 12px;
  }

  &-table {
    height: fit-content;

    &-wrapper {
      display: flex;

      background: white;
      flex-grow: 1;
    }
  }
}
