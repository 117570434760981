.excel-block{
  border: 1px dashed #D9D9D9;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  &_link{
    text-decoration: underline;
    color: #030852;
    :visited{
      color: #030852;
    }
  }
}