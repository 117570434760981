@import "src/styles/colors";

.header {
  padding: 7px 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: $blue;

  &-start {
    display: flex;
    flex-direction: row;
  }

  &-end {
    display: flex;
    flex-direction: row;
  }

  &-logo {
    height: 25px;
    margin-top: 2px;
  }

  &-divider {
    width: 1px;
    margin: 0 10px;
    background: white;
  }

  &-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    color: white;
  }
}
