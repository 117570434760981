.navButton-nav{
    font-size: 1rem;
    text-align: start;
    font-weight: 500;
    background: none;
    padding-top: 0.125rem;
    padding-bottom: 0.8em;
    position: relative;
    color: #737373;
    &__active{
        color: #4C4C4C;
        font-weight: 500;
        &::after{
            content: "";
            display: block;
            background-color: #2478F8;
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
        }
    }
}

.navButton-list{
    font-size: 1rem;
    text-align: start;
    font-weight: 500;
    background: none;
    padding-top: 0.125rem;
    padding-bottom: 0.8em;
    position: relative;
    color: #737373;
    &__active{
        color: #2478F8;
        font-weight: 500;
        text-decoration: underline;
  
    }
    &__complite{
        color: #48A300;
        font-weight: 400;
        text-decoration: underline;
    }
}