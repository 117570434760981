@import "src/styles/colors";

.tabs {
  display: flex;
  flex-direction: row;
  border-bottom: .5px solid $gray-3;
  margin-bottom: 12px;

  &-link {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: $gray-7;
    padding: 8px 24px;
  }

  &-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;

    width: fit-content;

    cursor: pointer;
    position: relative;
    padding: 8px 0;

    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: $gray-7;

    &-count {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      padding: 0 6px;
      background: $gray-3;
      border-radius: 100px;
      margin-left: 2px;
    }

    &_active {
      font-weight: 500;
      color: $blue;

      .tabs-item-count {
        color: white;
        background: $blue;
      }

      &:before {
        background: $blue;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    gap: 12px 24px;
  }
}
