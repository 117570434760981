.ImageCard {
  border: 2px dotted #b1b1b1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
  align-items: center;

  gap: 1rem;
  border-radius: 10px;

  &_header {
    align-self: flex-start;
    grid-column-start: 1;
    grid-column-end: -1;
    font-style: italic;
    color: gray;
    text-decoration: underline;
  }

  &_image {
    display: block;
    max-height: 150px;
    grid-column-start: 1;
    grid-column-end: -1;
    max-width: 90%;
    flex-grow: 0;
    border-radius: 5px;
    box-shadow: 6px 6px 5px #b1b1b1;
  }

  &_input {
    flex-shrink: 1;
  }

  &_input-description {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  &-buttons {
    display: flex;
    align-items: center;
  }

  &_button {
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    border-radius: 5px;
    color: white;
    background-color: rgb(173, 57, 57);
    padding: 7px;
  }
  &_button-box{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}
