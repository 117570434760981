.box-item{
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_link{
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &_image{
    max-width: 90%;
    max-height: 300px;
  }
}
