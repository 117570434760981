.textArea{
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 5px;
    width: 100%;
    &_label{
        font-size: 1rem;
        font-weight: 500;
        text-align: start;

    }
    &_input{
        min-height: 50px;
        max-height: 200px;
        width: 100%;
        padding: 7px 12px;

        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        border: 1px solid #D9D9D9;
        border-radius: 4px;
        resize: vertical;
        display: block;
        height: max-content;
    }
}