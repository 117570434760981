.template-page{
  display: grid;
  grid-template-columns: 2fr 5fr 5fr;

  grid-template-rows: min-content;
  column-gap: 20px;
  height: min-content;
  padding: 0 100px 20px 100px;

  &_header{
    font-size: 24px;
    margin-bottom: 22px;
    grid-column-start: 1;
    grid-column-end: -1;
  }
  &_column{
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  &_button{
    flex-grow: 2;
    display: flex;
    align-items: end;
    align-content: end;
    justify-content: end;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    &-group{
      display: flex;
      gap: 10px;
    }
  }
}
