.applyTemplatesModal{
    
    & h3{
        font-weight: 400;
    }
    &_nav{
        display: flex;
        justify-content: start;
        gap: 1rem;
        padding-bottom: 1rem;
    }

    &_main{
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-template-rows: 30px 30px 1fr;
        column-gap: 0.4rem;
        align-items: center;
        row-gap: 1rem;
        &-content{
            padding: 1rem;
            display: flex;
            flex-direction: column;
            border: 2px solid #D9D9D9;
            grid-column-start: 1;
            grid-column-end: -1;
            grid-row-start: 3;
            grid-row-end: -1;
            border-radius: 10px;
            height: calc(75vh - 140px);
            overflow: scroll;
            
        }
        &-object_item{
            display: grid;
            grid-template-columns: 1fr max-content;
        }
        &__screenshots{
            grid-column-start: 1;
            grid-column-end: -1;
            grid-row-start: 2;
            grid-row-end: -1;
        }
    }
    &_buttons{
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding-top: 1rem;
        justify-content: end;
    }

}

.content{
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 1rem;

}

.text-info{
    grid-column:2 / -1;
    color: #030852;
    font-size: 14px;
    text-align: justify;
    color: #868686;
}

.info-image{
    display: flex;
    justify-content: center;
    align-items: center;
}