.table{
  width: 100%;
  border: 0.5px solid #F0F0F0;
  border-collapse: collapse;
  font-size: 14px;
  line-height: 22px;
  text-align: start;
  &-row{
    position: relative;

    &__alert{

    }
  }
  &-cell{
    border:0.5px solid #F0F0F0 ;
    padding: 10px 8px;
    position: relative;
    text-align: start;

    &__alert:first-child{
      &::before{
        content: '';
        background-image: url(../../../../public/assets/icons/bell-alert-svgrepo-com.svg);
        background-size: contain ;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        width: 20px;
        height: 20px;

        position: absolute;
        left: -25px;
        top: calc(50% - 7px);
      }
    }
    &__bg-dark{
      background-color: #F9F9F9c0;
    }
    &_image{
      position: absolute;
      top: calc(50% - 6px) ;
      right: 8px;
    }
    &_checkbox{
      text-align: center;
      vertical-align: center;
      line-height: 0;
      width: max-content;
      padding: 5px;
    }
    &_header{

      user-select: none;
      padding-right: 25px;

      &__background{
        background: #f5f5f5;
      }
      &__pointer{
        cursor: pointer;
      }
    }

  }
  &_link{
    color: #030852;
    display: block;
    width: 100%;
    height: 100%;
    &:visited{
      color: #030852;
    }
  }


}

