.Spinner {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 4px solid #6ebeff;
    border-bottom-color: #2478F7;
    box-sizing:border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 