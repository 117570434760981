@import "src/styles/colors";

.project-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;

  &_active {
    .project-item-title,
    .project-item-text {
      color: $blue;
    }
  }

  &-title {
    font-weight: 400;
    color: #B3BDCE;
  }

  &-text {
    font-weight: 600;
    color: $geekblue-10;
  }

  &-button{
    color: $blue;
    background-color: white;

    text-decoration: underline;
    text-align: end;
    margin-top: -10px;
    margin-right: 10px;
  }
}


