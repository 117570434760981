@import "src/styles/colors";

.notification {
  position: fixed;
  left: 40px;

  padding: 18px 24px;
  border: 1px solid $gray-7;
  border-radius: 8px;

  background: white;
  opacity: 0;
  transition: .5s all linear;

  &_visible {
    opacity: 1;
    transition: .5s all linear;
  }

  &-content {
    position: relative;
  }

  &-title {
    color: $green-text;
    letter-spacing: 1px;

    &_disabled {
      color: $red;
    }
  }

  &-image {
    position: absolute;
    top: -18px;
    right: -22px;

    width: 20px;
    cursor: pointer;
  }
}
