@import "src/styles/colors";

.archive {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-left: 8px;
  color: $red;

  img {
    height: fit-content;
  }
}
