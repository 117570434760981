.content{
  margin: 20px 100px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &-buttons{
    display: flex;
    align-items: end;
    justify-content: space-between;
    padding-right: 20px;

    flex-grow: 1;
  }
  &-column{
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  &-info{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
  }
  &-cvss{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 0.3rem;
    &-item{
      display: grid;
      grid-template-columns: 70px 1fr;

      align-items: center;
    }
  }
}

.input{
  width: 250px;
}