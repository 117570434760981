@import "src/styles/colors";
.notification-popup{

    position: fixed;
    left: 40px;
    bottom: 40px;

    padding: 18px 24px;
    border: 1px solid $gray-7;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.15rem;
    background: white;
    opacity: 0;
    display: none;


    &__error{
        color: $red;
    }

    &__success{
        color: $green-text;

    }
    &__active{
        display: block;
        opacity: 1;
        transition: opacity .5s;
    }
    &__hidden{
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-name: vanish;
    }

}

@keyframes vanish {
    from{
        display: block;
        opacity: 1;
    }
    99%{
        opacity: 0;
        display: block;
    }
    100%{
        display: none;
    }
}