.ProgressUpload{
    &_item{
        font-size: 1.1rem;
        color: #48A300;
        font-weight: bold;
        &__error{
            color:#CC3D3C;
        }
    }

}