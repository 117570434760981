.button-group {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
}

.items-group {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  gap: 0.3rem;
  height: 70vh;
  overflow: scroll;
}

.item {
  padding: 10px 20px;
  background: transparent;
  text-align: start;
  font-size: 16px;
  border-radius: 3px;

  &:hover {
    background-color: #eee;
  }

  &-active {
    background-color: #2478F8;
    color: #fff;

    &:hover {
      background-color: #2478F890;
    }
  }
}
