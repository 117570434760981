$red: #FF364B;
$green: rgba(91, 255, 54, 0.82);
$green-text: rgb(65, 164, 40);

$blue: #2478F8;
$geekblue-10: #030852;
$geekblue-4: #85A5FF;
$geekblue-3: #ADC6FF;
$geekblue-1: #F0F5FF;

$gray-7: #8C8C8C;
$gray-6: rgba(0, 0, 0, 0.7);
$gray-5: #D9D9D9;
$gray-4: #F0F0F0;
$gray-3: #F5F5F5;
$gray-2: #F9F9F9;
