@import "src/styles/colors";

.item {
  &-content {
    height: 90%;

    padding: 20px 100px;
    position: relative;
  }

  &-body {
    display: flex;
    flex-direction: row;
    gap: 24px;

    &-info {
      display: flex;
      flex-direction: column;
      gap: 24px;

      background: white;
      padding: 24px;
      width: 20%;

      &-secondary {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 40%;

        padding: 24px 24px 20px;
        background: white;

        &-title {
          font-weight: 700;
          color: $geekblue-10;
        }

        &-list {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }

      &-title {
        font-weight: 700;
        color: $geekblue-10;
      }

      &-list {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }

  &-panel {
    display: flex;
    flex-direction: column;
    width: 40%;

    &-top {
      display: flex;
      flex-direction: column;
      gap: 16px;

      padding: 24px;
      background: white;

      &-wrapper {
        display: flex;
        flex-direction: column;
      }

      &-title {
        font-weight: 700;
        color: $geekblue-10;
      }

      &-buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px;
        align-items: center;

        &_secondary {
          padding-top: 16px;
          border-top: 1px solid $gray-4;
        }
      }
    }

    &-divider {
      width: 100%;
      height: 24px;
      background: white;
      margin-bottom: 24px;
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      padding: 24px;
      background: white;

      &-title {
        color: $geekblue-10;
        margin-bottom: 24px;
      }

      &-item {
        display: flex;
        flex-direction: row !important;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &-title {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $geekblue-10 !important;
        }

        &-text {
          font-weight: 600;
          font-size: 14px;
          color: black;
        }
      }
    }
  }
}
