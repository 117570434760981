.error {
  width: 100vw;
  height: 100vh;

  &-content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;

    height: 100vh;
    width: 35vw;
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 36px;
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;

    width: 100%;
    margin-top: 32px;
  }
}
