.select-list{
  display: flex;
  flex-direction: column;
  gap: 2px;
  &_header{
    font-weight: 400;
    padding-bottom: 7px;
    margin: 0;
    color: #030852;
  }
  &_items-bar{
    margin-top: 22px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 0.5rem;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    height: 585px;
  }
}