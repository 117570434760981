@import "src/styles/colors";
@import "src/styles/variables";

.popup {
  position: absolute;
  left: 0;
  top: 40px;

  display: none;
  flex-direction: column;

  padding: 8px 0;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
  border: .5px solid $gray-3;
  border-radius: 6px;

  background: $gray-2;
  width: 100%;
  max-height: 250px;
  overflow: auto;
  z-index: 2;

  &-text {
    cursor: pointer;

    padding: 8px 26px 8px 20px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;

    transition: $animation-duration all linear;
    color: $geekblue-10;
    background: transparent;

    &_active {
      background: $gray-3;
    }

    &:hover {
      background: $gray-4;
    }
  }
}
