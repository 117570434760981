$font-weight-text: 500;
$font-weight-title: 500;

$font-size-xs: 12px;
$line-height-xs: 18px;

$font-size-s: 14px;
$line-height-s: 20px;

$font-size-m: 16px;
$line-height-m: 24px;

$font-size-l: 18px;
$line-height-l: 26px;

$font-size-h1: 38px;
$line-height-h1: 40px;

$font-size-h2: 24px;
$line-height-h2: 32px;

$font-size-h3: 20px;
$line-height-h3: 28px;
